<template>
  <div>
    <div class="grid">
      <div class="col-12">
        <SelectButton v-model="selectedRange" :options="ranges" optionLabel="name"></SelectButton>
      </div>
      <div class="col-12" v-if="selectedRange.index !== 2">
        <SelectButton v-model="selectedInterval" :options="intervals" optionLabel="name"></SelectButton>
      </div>
    </div>
    <div class="grid">
      <svg id="bla" class="col-12"></svg>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import { eachMonthOfInterval, eachQuarterOfInterval } from 'date-fns';
import addMonths from 'date-fns/addMonths'
import isWithinInterval from 'date-fns/fp/isWithinInterval';
import min from 'date-fns/min';
import max from "date-fns/max";
export default {
  props: {
    cases: Array,
    x: Function,
    y: Function,
    minCasesPerPoint: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      selectedRange: { name: "Past 3 months" , index: 2 },
      ranges: [
        { name: "All Time" , index: 0 },
        { name: "Past year", index: 1 },
        { name: "Past 3 months", index: 2 },
      ],
      selectedInterval: { name: "Months", value: 'months' },
      intervals: [
        { name: "Months", value: 'months' },
        { name: "Quarters", value: 'quarters' },
      ],
      curve : d3.curveLinear, // method of interpolation between points
      marginTop : 20, // top margin, in pixels
      marginRight : 30, // right margin, in pixels
      marginBottom : 40, // bottom margin, in pixels
      marginLeft : 40, // left margin, in pixels
      width : 640, // outer width, in pixels
      height : 400, // outer height, in pixels
      xType : d3.scaleUtc, // the x-scale type
      yType : d3.scaleLinear, // the y-scale type
      yFormat : "%", // a format specifier string for the y-axis
      yLabel : "AQI ePreop 31", // a label for the y-axis
      color : "#c8c7f1", // stroke color of line
      strokeLinecap : "round", // stroke line cap of the line
      strokeLinejoin : "round", // stroke line join of the line
      strokeWidth : 2, // stroke width of line, in pixels
      strokeOpacity : 1, // stroke opacity of line
    }
  },
  computed: {
    earliestDate() {
      if (this.latestDate.length == 0)
        return addMonths(new Date(), -3)
      return min(this.cases.map(c => c.SGS_StartTime))
    },
    latestDate() {
      var end = new Date()
      const env = process.env.VUE_APP_ENV
      if ((!env || env != "prod") && this.cases?.length)
        end = max(this.cases.map(row => row.SGS_StartTime))
      return end
    },
    timeSteps() {
      let startDate;
      let intervalType = this.selectedInterval.value

      if (this.selectedRange.index == 0) {
        startDate = min([this.earliestDate, addMonths(this.latestDate, -12)]);
      } else if (this.selectedRange.index == 1) {
        startDate = addMonths(this.latestDate, -12);
      } else if (this.selectedRange.index == 2) {
        startDate = addMonths(this.latestDate, -3);
        intervalType = 'months'
      }

      return this.getIntervals(startDate, this.latestDate, intervalType);
    },
    data() {
      return this.timeSteps.map(timeStep => {
        const casesInInterval = this.cases.filter(aCase => {
          return isWithinInterval(timeStep, aCase.SGS_StartTime)
        })
        const casesPassing = casesInInterval.filter(aCase => {
          return aCase.STS_IOH_Under65 <= 15*60
        }).length
        const casesFailing = casesInInterval.filter(aCase => {
          return aCase.STS_IOH_Under65 > 15*60
        }).length
        let aqiMetric = Number.NaN
        if ((casesPassing+casesFailing) >= this.minCasesPerPoint)
          aqiMetric = casesFailing / (casesPassing + casesFailing)
        return {
          date: timeStep,
          close: aqiMetric,
          stdDev: Math.sqrt(aqiMetric*(1-aqiMetric)/(casesFailing+casesPassing))
        }
      })
    }
  },
  watch: {
    data() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      let xRange = [this.marginLeft, this.width - this.marginRight]
      let yRange = [this.height - this.marginBottom, this.marginTop]

      // Compute values.
      const X = d3.map(this.data, this.x);
      const Y = d3.map(this.data, this.y);
      const I = d3.range(X.length);

      // Compute default domains.
      let xDomain = d3.extent(X);
      let yDomain = [0, 1];

      // Construct scales and axes.
      const xScale = this.xType(xDomain, xRange);
      const yScale = this.yType(yDomain, yRange);
      let xAxisTicks = d3.timeMonth.every(1)
      let tickFormat = d3.timeFormat("%b '%y")
      if (this.selectedRange.index !== 2 && this.selectedInterval.value === 'quarters') {
        xAxisTicks = d3.timeMonth.every(3)
        tickFormat = d3.timeFormat("Q%q '%y");
      }
      if (this.selectedRange.index == 0)
        xAxisTicks = this.width / 40
      const xAxis = d3.axisBottom(xScale).ticks(xAxisTicks).tickSizeOuter(0).tickFormat(tickFormat);
      const yAxis = d3.axisLeft(yScale).ticks(this.height / 40, this.yFormat);

      // Construct a line generator.
      const line = d3.line()
          .curve(this.curve)
          .x(i => xScale(X[i]))
          .y(i => yScale(Y[i]))
          .defined(i => !Number.isNaN(Y[i]))

      const svg = d3.select("#bla")
          .attr("viewBox", [0, 0, this.width, this.height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      svg.selectAll("*").remove()

      svg.append("g")
          .attr("transform", `translate(0,${this.height - this.marginBottom})`)
          .call(xAxis);

      // X axis label:
      svg.append("text")
          .attr("x", this.width/2)
          .attr("y", this.height - 5)
          .attr("text-anchor", "middle")
          .attr("fill", "currentColor")
          .text("Month");

      svg.append("g")
          .attr("transform", `translate(${this.marginLeft},0)`)
          .call(yAxis)
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll(".tick line").clone()
              .attr("x2", this.width - this.marginLeft - this.marginRight)
              .attr("stroke-opacity", 0.1))
          .call(g => g.append("text")
              .attr("x", -this.marginLeft)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text(this.yLabel));

      svg.append("g")
          .selectAll("stddevbar")
          .data(this.data.filter(d => !Number.isNaN(d.close)))
          .enter()
          .append("line")
          .style("stroke", "red")
          .style("stroke-width", 1)
            .attr("x1", d => xScale(d.date.start) )
            .attr("x2", d => xScale(d.date.start))
            .attr("y1", d => yScale(d.close-d.stdDev))
            .attr("y2", d => yScale(d.close+d.stdDev))

      svg.append("g")
          .selectAll("stddevbar")
          .data(this.data.filter(d => !Number.isNaN(d.close)))
          .enter()
          .append("line")
            .style("stroke", "red")
            .style("stroke-width", 1)
            .attr("x1", d => xScale(d.date.start) - 5 )
            .attr("x2", d => xScale(d.date.start) + 5)
            .attr("y1", d => yScale(d.close+d.stdDev))
            .attr("y2", d => yScale(d.close+d.stdDev))

      svg.append("g")
          .selectAll("stddevbar")
          .data(this.data.filter(d => !Number.isNaN(d.close)))
          .enter()
          .append("line")
          .style("stroke", "red")
          .style("stroke-width", 1)
          .attr("x1", d => xScale(d.date.start) - 5 )
          .attr("x2", d => xScale(d.date.start) + 5)
          .attr("y1", d => yScale(d.close-d.stdDev))
          .attr("y2", d => yScale(d.close-d.stdDev))

      svg.append("path")
          .attr("fill", "none")
          .attr("stroke", this.color)
          .attr("stroke-width", this.strokeWidth)
          .attr("stroke-linecap", this.strokeLinecap)
          .attr("stroke-linejoin", this.strokeLinejoin)
          .attr("stroke-opacity", this.strokeOpacity)
          .attr("d", line(I));

      svg
          .append("g")
          .selectAll("dot")
          .data(this.data.filter(d => !Number.isNaN(d.close)))
          .enter()
          .append("circle")
            .attr("cx", function(d) { return xScale(d.date.start) } )
            .attr("cy", function(d) { return yScale(d.close) } )
            .attr("r", 5)
            .attr("fill", "#440154")

    },
    getIntervals(startDate, endDate, intervalType) {
      if (intervalType === 'months') {
        return eachMonthOfInterval({
          start: startDate,
          end: endDate
        }).map(startOfMonth => {
          return {
            start: startOfMonth,
            end: addMonths(startOfMonth, 1)
          };
        });
      } else if (intervalType === 'quarters') {
        return eachQuarterOfInterval({
          start: startDate,
          end: endDate
        }).map(startOfQuarter => {
          return {
            start: startOfQuarter,
            end: addMonths(startOfQuarter, 3)
          };
        });
      }
    throw new Error('Invalid interval type');
  }
}
};
</script>

<style scoped>
</style>