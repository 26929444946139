<template>
  <div style="position: relative; width:100%" >
    <svg id="donut"></svg>
  </div>
</template>
<script>
import * as d3 from "d3";
export default {
  props: {
    cases: Array,
  },
  data() {
    return {
      marginTop: 20, // the top margin, in pixels
      marginRight: 0, // the right margin, in pixels
      marginBottom: 30, // the bottom margin, in pixels
      marginLeft: 40, // the left margin, in pixels
      width: 400, // the outer width of the chart, in pixels
      height: 400, // the outer height of the chart, in pixels
      color: "#440154", // fill color
    }
  },
  computed: {
    aqiScore() {
      return this.numberOfFailingCases / this.numberOfCases
    },
    numberOfFailingCases() {
      return this.cases.filter(aCase => {
        return aCase.STS_IOH_Under65 > 15*60
      }).length
    },
    numberOfCases() {
      return this.cases.length
    }
  },
  watch: {
    aqiScore() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      // set the dimensions and margins of the graph
      const width = 450,
          height = 450,
          margin = 40;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin

      // append the svg object to the div called 'my_dataviz'
      d3.selectAll("g > *").remove()
      const svg = d3.select("#donut")
          .attr("viewBox", [0, 0, width, height])
          .append("g")
          .attr("transform", `translate(${width / 2},${height / 2})`);

      // Create dummy data
      var data = {pass: 1, fail: 0}
      if (this.aqiScore)
        data = {pass: 1-this.aqiScore, fail: this.aqiScore}

      // set the color scale
      const color = d3.scaleOrdinal()
          .range(["#c8c7f1", "#440154"])

      // Compute the position of each group on the pie:
      const pie = d3.pie()
          .value(d=>d[1])

      const data_ready = pie(Object.entries(data))

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
          .selectAll('whatever')
          .data(data_ready)
          .join('path')
          .attr('d', d3.arc()
              .innerRadius(200)         // This is the size of the donut hole
              .outerRadius(radius)
          )
          .attr('fill', d => color(d.data[0]))
          .style("opacity", 0.7)

      // Add the path using this helper function
      let aqiScoreText = "No data"
      if (!Number.isNaN(this.aqiScore))
        aqiScoreText = `${Math.round(this.aqiScore*100)}%`
      let numberOfCasesText = ""
      if (!Number.isNaN(this.numberOfCases))
        numberOfCasesText = `${this.numberOfFailingCases} of ${this.numberOfCases} cases`

      svg.append('text')
          .attr('x', 0)
          .attr('y', 0)
          .attr('stroke', '#440154')
          .attr('fill', '#440154')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'center')
          .style("font-size", 60)
          .text(aqiScoreText)

      svg.append('text')
          .attr('x', 0)
          .attr('y', height*0.15)
          .attr('stroke', '#440154')
          .attr('fill', '#440154')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'center')
          .style("font-size", 40)
          .style("font-weight", "lighter")
          .text(`${numberOfCasesText}`)
    }
  }
};
</script>

<style scoped>
</style>