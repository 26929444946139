<template>
  <div style="position: relative; width:100%" >
    <svg id="bar"></svg>
  </div>
</template>
<script>
import * as d3 from "d3";
export default {
  props: {
    data: Array,
    x: Function,
    y: Function
  },
  data() {
    return {
      marginTop: 20, // the top margin, in pixels
      marginRight: 0, // the right margin, in pixels
      marginBottom: 30, // the bottom margin, in pixels
      marginLeft: 40, // the left margin, in pixels
      width: 640, // the outer width of the chart, in pixels
      height: 350, // the outer height of the chart, in pixels
      yType: d3.scaleLinear, // y-scale type
      xPadding: 0.3, // amount of x-range to reserve to separate bars
      color: "#440154", // bar fill color
      yFormat: "%",
      yLabel: "Number of cases",
      xLabel: "X label",
    }
  },
  watch: {
    data() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    formatBarLabel(d) {
      if (d[1] - d[0] > 0)
        return (100*(d[1] - d[0])/(d.data.passCount + d.data.failCount)).toFixed(0) + "%"
      else
        return ""
    },
    render() {
      // Ranges
      const xRange = [this.marginLeft, this.width - this.marginRight]
      const yRange = [this.height - this.marginBottom, this.marginTop]
      // Compute values.
      const X = d3.map(this.data, this.x);

      // Compute default domains, and unique the x-domain. Y-axis max is least 10
      var xDomain = X;
      const yMax = Math.max(10, Math.max(...this.data.map(d => d.passCount + d.failCount)))
      var yDomain = [0, yMax];
      xDomain = new d3.InternSet(xDomain);

      // Construct scales, axes, and formats.
      const xScale = d3.scaleBand(xDomain, xRange).padding(this.xPadding);
      const yScale = this.yType(yDomain, yRange);
      const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
      const yAxis = d3.axisLeft(yScale).ticks(this.height / 40);


      const svg = d3.select("#bar")
          .attr("viewBox", [0, 0, this.width, this.height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

      svg.append("g")
          .attr("transform", `translate(${this.marginLeft},0)`)
          .call(yAxis)
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll(".tick line").clone()
              .attr("x2", this.width - this.marginLeft - this.marginRight)
              .attr("stroke-opacity", 0.1))
          .call(g => g.append("text")
              .attr("x", - this.marginLeft)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text(this.yLabel));

      const color = d3.scaleOrdinal()
          .domain(["failCount", "passCount"])
          .range(["#440154", "#c8c7f1"])

      const stackedData = d3.stack()
          .keys(["failCount", "passCount"])(this.data)

      // draw bars
      svg.append("g")
          .selectAll("g")
          .data(stackedData)
          .join("g")
          .attr("fill", d => color(d.key))
          .selectAll("rect")
          // enter a second time = loop subgroup per subgroup to add all rectangles
          .data(d => d)
            .join("rect")
            .attr("x", d => xScale(d.data.surgeryType))
            .attr("y", d => yScale(d[1]))
            .attr("height", d => yScale(d[0]) - yScale(d[1]))
            .attr("width", xScale.bandwidth())

      svg.append("g")
          .selectAll("g")
          .data(stackedData)
          .join("g")
          .selectAll("text")
          // enter a second time = loop subgroup per subgroup to add all rectangles
          .data(d => d)
            .join("text")
            .attr("x", d => xScale(d.data.surgeryType) + xScale.bandwidth()/2)
            .attr("y", d => yScale(d[0] + (d[1]-d[0])/2))
            .style("text-anchor", "middle")
            .style("alignment-baseline", "middle")
            .style("font-weight", "bold")
            .style("font-size", "10")
            .style("fill", "white")
            .text(d => this.formatBarLabel(d))

      // draw bar labels
      svg.append("g")
          .selectAll("g")
          .data(stackedData)
          .join("g")
          .attr("stroke", "red")
          .selectAll("text")
          // enter a second time = loop subgroup per subgroup to add all rectangles
          .data(d => d)
            .append("text")
            .attr("x", d => xScale(d.data.surgeryType))
            .attr("y", d => yScale(d[1]))
            .attr("text-anchor","middle")
            .text("ff")

      const rotation = -15
      svg.append("g")
          .attr("transform", `translate(0,${this.height - this.marginBottom})`)
          .call(xAxis)
          .selectAll("text")
            .attr("y", 15)
            .attr("transform", `rotate(${rotation})`)
            .style("text-anchor", "middle");

      // Add one dot in the legend for each name.
      var size = 12
      svg.append("rect")
          .attr("x", 0.2*this.width - size)
          .attr("y", 0)
          .attr("width", size)
          .attr("height", size)
          .style("fill", this.color)
      svg.append("rect")
          .attr("x", 0.65*this.width - size)
          .attr("y", 0)
          .attr("width", size)
          .attr("height", size)
          .style("fill", "#c8c7f1")

      svg.append("text")
          .attr("x", 0.2*this.width + size)
          .attr("y", size/2)
          .style("fill", this.color)
          .style("font-size", "10")
          .text("Cumulative hypotension ≥ 15mins (AQI ePreop31)")
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")

      svg.append("text")
          .attr("x", 0.65*this.width + size)
          .attr("y", size/2)
          .style("fill", this.color)
          .style("font-size", "10")
          .text("Cumulative hypotension < 15mins")
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")
    }
  }
};
</script>

<style scoped>
</style>