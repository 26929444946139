<template>
		<div class="grid p-fluid">
      <div class="col-12 md:col-4">
        <div class="card">
          <div class="chart-container">
            <div class="header">
              <h3>
                AQI ePreop31
              </h3>
              <h5>
                Percentage of general anesthesia cases in which mean arterial pressure (MAP) fell below 65 mmHg for cumulative total of 15 minutes or more
              </h5>
            </div>
            <div class="centered-chart">
              <D3DonutChart
                  :cases="lastQuarterRows"></D3DonutChart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-8">
        <div class="card ">
          <div>
            <h3>
              AQI ePreop31
            </h3>
            <h5>
              Percentage of general anesthesia cases in which mean arterial pressure (MAP) fell below 65 mmHg for cumulative total of 15 minutes or more
            </h5>
          </div>
          <D3BarChart
            :data="aqiBySurgeryType"
            :x="d => d.surgeryType"
            :y="d => d.failCount"></D3BarChart>
        </div>
      </div>
      <div class="col-12 md:col-8">
        <div class="card">
          <div>
            <h3>
              AQI ePreop31 (variation over time)
            </h3>
            <h5>
              Percentage of general anesthesia cases in which mean arterial pressure (MAP) fell below 65 mmHg for cumulative total of 15 minutes or more
            </h5>
          </div>
          <D3Chart
            :cases="visibleSurgeryTableRows"
            :x="d => d.date.start"
            :y="d => d.close"
            :minCasesPerPoint="3"></D3Chart>
        </div>
      </div>
    </div>
</template>

<script>
import {API} from "aws-amplify";
import D3BarChart from "./D3BarChart";
import D3Chart from "./D3Chart";
import D3DonutChart from "./D3DonutChart";
import addMonths from 'date-fns/addMonths'
import isWithinInterval from 'date-fns/fp/isWithinInterval';
import max from 'date-fns/max';
import {parseISO} from "date-fns";
export default {
  name: "AqiMetric",
	data() {
		return {
      tabIndex: 1,
      hypoThreshold: 20,
      hypoBySurgeryFailThreshold: 15,
      tabIndexRate: 1,
      surgeryTypes: [
        "Not entered",
        "Cardiac",
        "Neuro",
        "General",
        "Urology",
        "Gynecology",
        "ENT",
        "Plastics",
        "Vascular",
        "Thoracic",
        "Orthopedics",
        "Other",
      ],
      allSurgeryTableRows: [],
      visibleSurgeryTableRows: [],
      lastQuarterRows: [],
      showLoading1:false,
      showLoading2:false,
      showRotate:false,
      hypoDurationId:'chartDuration55mmHg',
      selectedThreshold :{name: 'Duration of IOH for Mean Arterial Pressure  < 65mmHg', code: 1},
      selectedThresholdRate :{name: 'Rates of hypotension by surgery type for Mean Arterial Pressure  < 65mmHg', code: 1},
      facilities : []
		}
	},
	customerService: null,
	mounted() {
    this.showLoading2 = true
    this.showLoading1 = true;
    API
        .get("TestAPI", "/surgery-sessions", null)
        .then(response => {
          this.allSurgeryTableRows = response.map(row => {
            row.SGS_StartTime = parseISO(row.SGS_StartTime)
            row.SGS_EndTime = parseISO(row.SGS_EndTime)
            row.SPI_Created = parseISO(row.SPI_Created)
            return row
          })
          this.visibleSurgeryTableRows = this.allSurgeryTableRows;
          this.lastQuarterRows = this.allSurgeryTableRows//this.getLastQuarter(this.allSurgeryTableRows)
          this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);
          this.facilities = [...new Set(this.visibleSurgeryTableRows.map(item => item.SGS_FacilityName))];
          this.$store.dispatch("addFacilityOptions",this.facilities.map(x=> {return { name:x , code:x };}));
          this.showLoading1 = false;
        })
        .catch(error => {
          console.log(error.response);
          this.showLoading1 = false;
        })
        .finally(()=>{
          this.showLoading1 = false;
        });
	},
	methods: {
    getLastQuarter(surgeryTableRows) {
      var end = new Date()
      const env = process.env.VUE_APP_ENV
      if ((!env || env != "prod"))
        end = max(surgeryTableRows.map(row => row.SGS_StartTime))
      const start = addMonths(end, -3)
      const res = surgeryTableRows.filter(row => {
        return isWithinInterval({
          start: start,
          end: end,
        },
        row.SGS_StartTime)
      })
      return res
    },
    getHypoDurations(iohthreshold) {
      return this.visibleSurgeryTableRows
          .map(row => {
            this.hypoDurationId ='chartDuration'+iohthreshold.toString()+'mmHg';
            if(iohthreshold===55) {
              return row.STS_IOH_Under55 / 60;
            }
            else if(iohthreshold===65) {
              return row.STS_IOH_Under65 / 60;
            }
            else if (iohthreshold===75) {
              return row.STS_IOH_Under75 / 60;
            }
            return 0;
          });
    },
    getIohPassCountBySurgeryType(iohthreshold){
      return this.surgeryTypes.map(surgeryType => {
        return this.lastQuarterRows.filter(row => {
          if(row.SPI_SurgeryType === surgeryType
              || (row.SPI_SurgeryType===null && surgeryType==="Not entered")
              || surgeryType == "All") {
            if (iohthreshold === 55) {
              return row.STS_IOH_Under55 / 60 <= 5;
            } else if (iohthreshold === 65) {
              return row.STS_IOH_Under65 / 60 <= 15;
            } else if (iohthreshold === 75) {
              return row.STS_IOH_Under75 / 60 <= 30;
            }
            else{
              return false;
            }

          }
          else {
            return false;
          }

        }).length
      })
    },
    getIohFailCountBySurgeryType(iohthreshold) {
      return this.surgeryTypes.map(surgeryType => {
        return this.lastQuarterRows.filter(row => {
          if(row.SPI_SurgeryType === surgeryType
              || (row.SPI_SurgeryType===null && surgeryType==="Not entered")
              || surgeryType == "All") {
            if (iohthreshold === 55) {
              return row.STS_IOH_Under55 / 60 > 5;
            } else if (iohthreshold === 65) {
              return row.STS_IOH_Under65 / 60 > 15;
            } else if (iohthreshold === 75) {
              return row.STS_IOH_Under75 / 60 > 30;
            }
            else{
              return false;
            }
          }
          else{
            return false;
          }
        }).length
      })
    },
    onSubmitFilter(args) {
      this.visibleSurgeryTableRows = this.allSurgeryTableRows;
      if(args)
      {
        this.$store.dispatch("updateFilter",args);

      }
      else
      {
        this.$store.dispatch("emptyFilter");
      }

      this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);

    },
	},
  components: {
    D3DonutChart,
    D3Chart,
    D3BarChart,
  },
  computed:{
    checkSpinner1(){
      return this.showLoading1?'Ok':'';
    },
    checkSpinner2(){
      return this.showLoading2?'Ok':'';
    },
    hypoDurations() {
      if(this.tabIndex===0){
        return  this.getHypoDurations(55);
      }
      else if(this.tabIndex===1){
        return  this.getHypoDurations(65);
      }
      else if(this.tabIndex===2){
        return  this.getHypoDurations(75);
      }
      else {
        return [];
      }
    },
    iohPassCountBySurgeryType(){
      if(this.tabIndexRate===0){
        return  this.getIohPassCountBySurgeryType(55);
      }
      else if(this.tabIndexRate===1){
        return  this.getIohPassCountBySurgeryType(65);
      }
      else if(this.tabIndexRate===2){
        return  this.getIohPassCountBySurgeryType(75);
      }
      else {
        return [];
      }
    },
    iohFailCountBySurgeryType() {
      if(this.tabIndexRate===0){
        return  this.getIohFailCountBySurgeryType(55);
      }
      else if(this.tabIndexRate===1){
        return  this.getIohFailCountBySurgeryType(65);
      }
      else if(this.tabIndexRate===2){
        return  this.getIohFailCountBySurgeryType(75);
      }
      else {
        return [];
      }
    },
    aqiBySurgeryType() {
      const failCount = this.getIohFailCountBySurgeryType(65);
      const passCount = this.getIohPassCountBySurgeryType(65)
      return failCount.map((thisFailCount, i) => {
        return {
          surgeryType: this.surgeryTypes[i],
          failCount: thisFailCount,
          passCount: passCount[i]
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;
  .p-progressbar-value {
    background-color: #607D8B;
  }
}
.card {
  height: 100%;
}
.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  height: 100%;
}
.centered-chart {
  flex-grow: 1;
  display: flex;
  align-items: center; /* Center the chart vertically inside its container */
  justify-content: center; /* Center the chart horizontally (optional, if needed) */
}
</style>